<template>
<span>
    <v-layout mb-4>
        <v-flex xs10>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Modelos - Edição</h2>
        </v-flex>
        <v-flex xs2 text-xs-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'product-models'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-product-model :productmodel="productmodel" :update="true"></form-product-model>
</span>
</template>

<script>
import FormProductModelComponent from "./partial/FormProductModelComponent";

export default {
    name: "AddProductModelComponent",
    created() {
        this.loadProductModel()
    },
    props: {
        prod_mo_id: {
            require: true
        }
    },
    data() {
        return {
            productmodel: {
                prod_mo_id: '',
                prod_mo_nome: '',
                prod_mo_slug: '',
                prod_mo_descricao: '',
                prod_mo_logo: '',
                prod_mo_banner: '',
                prod_mo_status: false,
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            langs: true
        }
    },
    methods: {
        loadProductModel() {
            this.$store.dispatch('loadProductModel', this.prod_mo_id)
                .then(response => this.productmodel = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formProductModel: FormProductModelComponent
    }
};
</script>

<style scoped>

</style>
